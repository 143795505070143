@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Futura Next;
  src: url("./fonts/FuturaNext_Book.otf");
  font-display: swap;
}

* {
  box-sizing: border-box;
  font-size: 16px;
  font-family: Futura Next;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff0e8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 
  User defined styles
*/

.white {
  color: #fff;
}

.black {
  color: #000;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ta-center {
  text-align: center;
}

.font-500 {
  font-weight: 500;
}
